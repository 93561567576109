.info--picture {
    width: 200px;
    height: 300px;
    object-fit: cover;
    transition: filter 1s ease-in-out;
    border-radius: 5px;
  }
  
  .blur {
    filter: blur(5px);
  }
  
  

.centered-container-info {
    text-align: center;
    font-family: "Inter";
    color: white;
  }

  
.name{
    font-size: larger;
    margin-top: 12px;
} 

.role {
    font-size: smaller;
  
}

.lenguaje{
    font-weight: 700;
    color: lightblue;
}

body {
    background-color: rgb(31, 33, 40);
}

.button-container {
    display: flex;
    justify-content: center;
    width: 100%; /* take full width */
}

.button{
    /* make buttons more elongated*/
    width: 20%;
    margin: 0 30px;
}

.Justified-container-info{
    color: white;
}

.Justified-container-info li{
    text-align: justify;

}

/* when anchor tag with class tag-main clicked, the hyperlink is white color*/
.tag-main:visited{
    color: white;
}

.tag-main{
    color: white;
}

.tag-secondary{
    color:rgb(48, 125, 246)
}

.tag-secondary:visited{
    color:rgb(48, 125, 246)
}

ul {
  list-style-type: disc;
}
    


.button:hover {
    transform: scale(1.05);  /* Enlarge the button slightly */
    transition: transform 0.3s ease-in-out;  /* Smooth transition */
}

.button a:hover {
    text-decoration: none;
}
